import Vue from 'vue'
import VModal from 'vue-js-modal'
import axios from 'axios'

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email, max, regex } from 'vee-validate/dist/rules'

extend('required', required)
extend('email', email)
extend('max', max)
extend('regex', regex)

import utils from 'mixins/utils'

Vue.use(VModal)

const ViewProps = window._application.props,
  form = ViewProps.requestCvForm,
  fieldNames = {
    companyName: 'Company Name',
    fullName: 'Full Name',
    position: 'Position',
    phoneNumber: 'Phone Number',
    email: 'Email'
  },
  validationRules = {
    companyName: 'required|max:255',
    fullName: 'required|max:255',
    position: 'required|max:255',
    phoneNumber: 'required|max:15|regex:^[0-9+]+$',
    email: 'required|email|max:255'
  }

export default {
  name: 'RequestCV',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [utils],
  data() {
    return {
      requestCvNew: true,
      requestCvNewHtmlClass: null,
      requestCvComplete: false,
      csrfToken: ViewProps.csrfToken,
      fieldNames: fieldNames,
      validationRules: validationRules,
      candidate: '',
      requestCV: form.requestCv,
      baseErrors: []
    }
  },
  computed: {
    candidateId() {
      return this.candidate.id
    },
    candidateCode() {
      return this.candidate.code
    },
    candidatePosition() {
      return this.candidate.position
    }
  },
  methods: {
    beforeOpen(event) {
      this.candidate = event.params.candidate
    },
    switchToNew() {
      this.requestCvNew = true
      this.requestCvComplete = false
    },
    switchToComplete() {
      this.requestCvNew = false
      this.requestCvComplete = true
    },
    async onSubmit(event) {
      const self = this,
        config = {
          headers: { 'X-CSRF-Token': event.target.authenticityToken.value }
        },
        formValidate = await this.$refs.form.validate(),
        formData = { requestCv: this.requestCV }

      if (formValidate) {
        this.requestCvNewHtmlClass = 'loading'

        formData.requestCv.candidateId = event.target.candidateId.value

        axios
          .post('/request_cvs', formData, config)
          .then((response) => {
            this.requestCvNewHtmlClass = null

            if (Object.keys(response.data.errors).length) {
              this.$refs.form.setErrors(response.data.errors)
            } else {
              this.switchToComplete()
            }
          })
          .catch((error) => {
            const errorMessage = error || 'Error : Something error happened.'

            self.requestCvNewHtmlClass = null
            self.$refs.form.setErrors({ base: [errorMessage] })
          })
      }
    },
    close() {
      this.$modal.hide('RequestCV')
      this.switchToNew()
    },
    closed() {
      this.switchToNew()
    }
  }
}
