<template lang="pug">
  #modals
    RequestCV
</template>
<script>
import RequestCV from './modals/RequestCV'

export default {
  components: {
    RequestCV
  }
}
</script>
