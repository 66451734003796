import Vue from 'vue'
import SuiVue from 'semantic-ui-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(SuiVue)

import App from 'views/home/Index.vue'

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    render: (h) => h(App)
  }).$mount()
  document.body.appendChild(app.$el)
})
