<template>
  <footer>
    <div v-if="path === '/'" class="achievement section">
      <ContentFooter />
    </div>
    <div class="footer-wrap">
      <div class="d-flex justify-content-between">
        <a href="https://www.reeracoen.co.th/en/info" target="_blank"
          ><h3>About Us</h3></a
        >
        <a href="https://www.reeracoen.co.th/en/privacy_policy" target="_blank"
          ><h3>Privacy Policy</h3></a
        >
        <a href="https://www.reeracoen.co.th/en/term" target="_blank"
          ><h3>Terms of Use</h3></a
        >
      </div>
      <div class="d-flex justify-content-center mt-5">
        <span class="social-item" href="#">Follow Us</span>
        <a
          class="social-item"
          href="https://www.facebook.com/ReeracoenRecruitment/"
          target="_blank"
          ><FontAwesomeIcon
            :icon="{ prefix: 'fab', iconName: 'facebook-f' }"
          ></FontAwesomeIcon
        ></a>
        <a
          class="social-item"
          href="https://www.linkedin.com/company/reeracoen-recruitment-co--ltd/"
          target="_blank"
          ><FontAwesomeIcon
            :icon="{ prefix: 'fab', iconName: 'linkedin-in' }"
          ></FontAwesomeIcon
        ></a>
        <a
          class="social-item"
          href="https://line.me/R/ti/p/%40ltj1961t"
          target="_blank"
          ><img
            class="line-logo"
            :src="require('images/logo/line-logo.png')"
            alt="Line Account"
        /></a>
        <a
          class="social-item"
          href="mailto:rcnsupport@reracoen.co.th"
          target="_blank"
          ><FontAwesomeIcon
            :icon="{ prefix: 'fas', iconName: 'envelope' }"
            alt="rcnsupport@reracoen.co.th"
          ></FontAwesomeIcon
        ></a>
      </div>
      <div class="d-flex">
        <span class="copyright"
          >Copyright © 2021 Reeracoen. All rights reserved.</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faLinkedinIn,
  faLine
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ContentFooter from 'components/ui/ContentFooter.vue'

library.add(faFacebookF)
library.add(faLinkedinIn)
library.add(faLine)
library.add(faEnvelope)
library.add(faPhoneVolume)

export default {
  components: {
    FontAwesomeIcon,
    ContentFooter
  },

  data() {
    const path = window.location.pathname
    return {
      path
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'stylesheets/application';

a {
  color: $white;
  font-size: 1rem;
  font-weight: lighter;
  letter-spacing: 0.81px;
  opacity: 1;
  text-align: left;
  text-decoration: none;
}

p {
  font-weight: 100;
  margin: 0;
  padding: 0;
}

footer {
  background-image: linear-gradient(to top, #0080cb 40%, #139eff 100%);
  color: $white;
  display: block;
  width: 100%;

  .achievement::before {
    background-color: black;
    content: ' ';
    display: block;
    height: 100%;
    opacity: 0.7;
    position: absolute;
    transition: all 0.4s ease-in-out;
    width: 100%;
  }

  .achievement {
    background-attachment: fixed;
    background-image: url('images/tech-talent/contact-bg.jpg');
    background-position: center center;
    background-size: cover;
    color: $white;
    height: 500px;
    padding: 0;
    position: relative;

    .note {
      color: rgba(255, 255, 255, 0.8);
      font-size: 13px;
      padding: 1rem;
      text-align: center;
    }
  }
}

.footer-wrap {
  display: flex;
  flex-direction: column;
  font-size: 90%;
  padding: 2rem;

  h3 {
    @include font-header-2(1.15rem, 0, $white);
  }
}

.line-logo {
  width: 20px;
}

.copyright {
  color: $white;
  display: block;
  font-size: 12px;
  font-weight: 300;
  margin-top: 2rem;
  opacity: 0.5;
  padding: 2rem;
  text-align: center;
  width: 100%;
}

.social-item {
  margin-right: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 1080px) {
  footer {
    background-image: linear-gradient(to top, #0080cb 40%, #139eff 100%);
    color: $white;
    display: block;
    width: 100%;

    .achievement {
      background-attachment: fixed;
      background-image: url('../../../images/tech-talent/contact-bg.jpg');
      background-position: center center;
      background-size: cover;
      color: $white;
      height: 500px;
      padding: 0;
      position: relative;

      &::before {
        background-color: black;
        content: ' ';
        display: block;
        height: 100%;
        opacity: 0.7;
        position: absolute;
        transition: all 0.4s ease-in-out;
        width: 100%;
      }

      .note {
        color: rgba(255, 255, 255, 0.8);
        font-size: 13px;
        padding: 1rem;
        text-align: center;
      }
    }

    .footer-wrap {
      display: block;
      flex-direction: row;
      font-size: 100%;
      padding: 4rem 4rem;

      h3 {
        @include font-header-2(1rem, 0, $white);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  footer {
    .achievement {
      background-attachment: unset;
      background-image: url('../../../images/tech-talent/contact-bg-sp.jpg');
    }

    .footer-wrap {
      .d-flex.justify-content-between {
        align-items: center;
        flex-direction: column;

        a {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  footer {
    .achievement {
      &::before {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  footer {
    .achievement {
      &::before {
        width: 100%;
      }
    }
  }
}
</style>
