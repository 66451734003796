<template>
  <div>
    <Header />
    <slot />
    <Footer />
    <Modals />
  </div>
</template>

<script>
import Header from 'components/layouts/application/Header.vue'
import Footer from 'components/layouts/application/Footer.vue'
import Modals from 'components/layouts/application/Modals.vue'

export default {
  name: 'ApplicationLayout',

  components: {
    Header,
    Footer,
    Modals
  }
}
</script>
