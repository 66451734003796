<template>
  <header>
    <div class="navbar-wrap">
      <div
        class="navbar"
        :class="path === '/' ? 'nav-transparent' : 'nav-blue'"
      >
        <div class="nav-logo">
          <a href="/"
            ><img
              class="responsive"
              :src="require('images/logo/rcn_logo_wh.png')"
              alt="Reeracoen Recruitment Co.,Ltd."
          /></a>
        </div>
        <div class="navbar-right">
          <div class="social">
            <a
              class="social-item fb-color"
              href="https://www.facebook.com/ReeracoenRecruitment/"
              target="_blank"
              ><FontAwesomeIcon
                :icon="{ prefix: 'fab', iconName: 'facebook-f' }"
              ></FontAwesomeIcon
            ></a>
            <a
              class="social-item in-color"
              href="https://www.linkedin.com/company/reeracoen-recruitment-co--ltd/"
              target="_blank"
              ><FontAwesomeIcon
                :icon="{ prefix: 'fab', iconName: 'linkedin-in' }"
              ></FontAwesomeIcon
            ></a>
            <a
              class="social-item line-color"
              href="https://line.me/R/ti/p/%40ltj1961t"
              target="_blank"
              ><img
                class="line-logo"
                :src="require('images/logo/line-logo.png')"
                alt="Line Account"
            /></a>
            <a
              class="social-item phone-color"
              href="tel:+66022539800"
              target="_blank"
            >
              <FontAwesomeIcon
                :icon="{ prefix: 'fas', iconName: 'phone-alt' }"
              ></FontAwesomeIcon>
            </a>
            <a class="tel" href="tel:+66022539800" target="_blank"
              >(+66) 02-253-9800</a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="path === '/'" class="hero-wrap">
      <div class="hero-caption">
        <h1>Hot IT and Digital <br />Talent List</h1>
        <h2>By Reeracoen Recruitment Co., Ltd.</h2>
        <div class="btn">
          <a href="https://www.reeracoen.co.th/en/inquiries/new" target="_blank"
            >Contact Us</a
          >
        </div>
      </div>
      <div class="desktop">
        <img
          class="responsive"
          :src="require('images/banner/desktop/header-pc.jpg')"
          alt="Key Banner Desktop"
        />
      </div>
      <div class="mobile">
        <img
          class="responsive"
          :src="require('images/banner/mobile/header-sp.jpg')"
          alt="Key Banner Mobile"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faLinkedinIn,
  faLine
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebookF)
library.add(faLinkedinIn)
library.add(faLine)
library.add(faEnvelope)
library.add(faPhoneAlt)

export default {
  components: {
    FontAwesomeIcon
  },

  data() {
    const path = window.location.pathname
    return {
      path
    }
  }
}
</script>

<style lang="scss" src="./Header.scss" scoped />
