import counterUp from 'counterup2'
import 'waypoints'
import 'waypoints-inview'

import ApplicationLayout from 'components/layouts/Application.vue'
import JobList from 'components/views/candidates/ListCandidates.vue'
import CandidateSearchForm from 'components/views/candidates/SearchFormCandidate.vue'

export default {
  name: 'HomeIndex',

  components: {
    ApplicationLayout,
    CandidateSearchForm,
    JobList
  },

  data() {
    return {
      listSkill: [
        {
          id: 1,
          name: 'Java',
          path: require('images/tech-talent/skill/java.png'),
          amount: 500
        },
        {
          id: 2,
          name: 'Python',
          path: require('images/tech-talent/skill/python.png'),
          amount: 350
        },
        {
          id: 3,
          name: '.Net',
          path: require('images/tech-talent/skill/net.png'),
          amount: 1000
        },
        {
          id: 4,
          name: 'C#',
          path: require('images/tech-talent/skill/objectc.png'),
          amount: 500
        },
        {
          id: 5,
          name: 'Javascript',
          path: require('images/tech-talent/skill/js.png'),
          amount: 800
        },
        {
          id: 6,
          name: 'CSS',
          path: require('images/tech-talent/skill/css.png'),
          amount: 700
        },
        {
          id: 7,
          name: 'PHP',
          path: require('images/tech-talent/skill/php.png'),
          amount: 1000
        },
        {
          id: 8,
          name: 'HTML',
          path: require('images/tech-talent/skill/html.png'),
          amount: 800
        }
      ]
    }
  },
  methods: {
    countUpInview() {
      const self = this,
        waypointInviewAttr = {
          element: self.$refs.talentsList,
          enter: function () {
            for (const index in self.listSkill) {
              counterUp(self.$refs.counterUp[index], {
                time: 1000,
                delay: 10
              })
            }
          }
        }

      // eslint-disable-next-line no-undef
      new Waypoint.Inview(waypointInviewAttr)
    }
  },
  mounted() {
    this.countUpInview()
  }
}
