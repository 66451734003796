<template>
  <sui-form action="candidates">
    <div class="field-row">
      <sui-form-field>
        <label>Position</label>
        <Multiselect
          v-model="jobCategories"
          :options="jobCategoryOptions"
          :multiple="true"
          :close-on-select="false"
          placeholder="Position Search"
          label="text"
          track-by="value"
          :show-labels="false"
          :searchable="false"
        >
          <template slot="selection" slot-scope="{ values }">
            <span v-if="values.length" class="multiselect__single">
              {{ values.length }} Position Selected
            </span>
          </template>
        </Multiselect>
        <input
          :value="toParameter(jobCategories)"
          name="s[job_categories]"
          type="hidden"
        />
      </sui-form-field>
      <sui-form-field>
        <label>Experience</label>
        <Multiselect
          v-model="experiences"
          :options="experienceOptions"
          :multiple="true"
          :close-on-select="false"
          placeholder="Experience Search"
          label="text"
          track-by="value"
          :show-labels="false"
          :searchable="false"
        >
          <template slot="selection" slot-scope="{ values }">
            <span v-if="values.length" class="multiselect__single">
              {{ values.length }} Experience Selected
            </span>
          </template>
        </Multiselect>
        <input
          :value="toParameter(experiences)"
          type="hidden"
          name="s[experiences]"
        />
      </sui-form-field>
      <sui-form-field>
        <label>Technical Skills</label>
        <input
          v-model="techSkill"
          type="text"
          name="s[freeword]"
          placeholder="Enter Keyword"
        />
      </sui-form-field>
    </div>
    <div class="field-row">
      <sui-form-field>
        <label>English Skill Level </label>
        <Multiselect
          v-model="englishLevels"
          :options="englishLevelOptions"
          :multiple="true"
          :close-on-select="false"
          placeholder="Level Search"
          label="text"
          track-by="value"
          :show-labels="false"
          :searchable="false"
        >
          <template slot="selection" slot-scope="{ values }">
            <span v-if="values.length" class="multiselect__single">
              {{ values.length }} Level Selected
            </span>
          </template>
        </Multiselect>
        <input
          :value="toParameter(englishLevels)"
          type="hidden"
          name="s[english_level]"
        />
      </sui-form-field>
      <sui-form-field>
        <label>Maximum Monthly Salarys</label>
        <input
          v-model="salary"
          class="input-number"
          type="number"
          name="s[expected_salary]"
          placeholder="Expected Salary"
          @input="(e) => checkValueSalary(e)"
        />
      </sui-form-field>
      <button class="submit-btn" type="submit">
        <FontAwesomeIcon
          class="search-icon"
          :icon="{ prefix: 'fas', iconName: 'search' }"
        >
        </FontAwesomeIcon>
      </button>
    </div>
  </sui-form>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

library.add(faSearch)

const ViewProps = window._application.props
const path = window.location.pathname

export default {
  components: {
    Multiselect,
    FontAwesomeIcon
  },

  data() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    return {
      optionSelect: null,
      jobCategories: [],
      englishLevels: [],
      experiences: [],
      techSkill: params['s[freeword]'] ? params['s[freeword]'] : null,
      salary: params['s[expected_salary]']
        ? params['s[expected_salary]']
        : null,
      params,
      path
    }
  },

  computed: {
    jobCategoryOptions() {
      return this.buildOptions(ViewProps.candidateSearchForm.jobCategoryOptions)
    },

    englishLevelOptions() {
      return this.buildOptions(
        ViewProps.candidateSearchForm.englishLevelOptions
      )
    },

    experienceOptions() {
      return this.buildOptions(ViewProps.candidateSearchForm.experienceOptions)
    }
  },

  mounted() {
    this.bindJobCategories()
    this.bindExperiences()
    this.bindEnglishLevels()
  },

  methods: {
    buildOptions(options) {
      let child = []
      options.map((option) => {
        child = option.children.map((child) => {
          return {
            ...child
          }
        })
      })
      return child
    },
    bindJobCategories() {
      const param = this.params['s[job_categories]'] || ''

      param.split('-').forEach((code) => {
        let targetOption = this.jobCategoryOptions.find(
          (option) => option.value === parseInt(code)
        )

        if (targetOption) {
          this.jobCategories.push(targetOption)
        }
      })
    },
    bindExperiences() {
      const param = this.params['s[experiences]'] || ''

      param.split('-').forEach((code) => {
        let targetOption = this.experienceOptions.find(
          (option) => option.value === parseInt(code)
        )

        if (targetOption) {
          this.experiences.push(targetOption)
        }
      })
    },
    bindEnglishLevels() {
      const param = this.params['s[english_level]'] || ''

      param.split('-').forEach((code) => {
        let targetOption = this.englishLevelOptions.find(
          (option) => option.value === parseInt(code)
        )

        if (targetOption) {
          this.englishLevels.push(targetOption)
        }
      })
    },
    toParameter(options) {
      return options
        .map((option) => {
          return option.value
        })
        .join('-')
    },
    checkValueSalary(e) {
      let val = e.target.value
      this.salary = val < 0 ? null : val
    }
  }
}
</script>

<style lang="scss" src="./SearchFormCandidate.scss" scoped />
