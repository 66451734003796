<template>
  <div class="content">
    <div class="title">
      <h2>Contact Us</h2>
    </div>
    <div class="title-2">
      <h2>Reeracoen Recruitment Co., Ltd.</h2>
    </div>
    <div class="contact-box">
      <ul class="list">
        <li class="item">
          <div class="icon">
            <FontAwesomeIcon
              :icon="{ prefix: 'fas', iconName: 'desktop' }"
            ></FontAwesomeIcon>
          </div>
          <div class="link">
            <a href="https://www.reeracoen.co.th/" target="_blank"
              >Reeracoen Website</a
            >
          </div>
        </li>
        <li class="item">
          <div class="icon">
            <FontAwesomeIcon
              :icon="{ prefix: 'fas', iconName: 'phone-alt' }"
            ></FontAwesomeIcon>
          </div>
          <div class="link">
            <a href="tel:+66022539800" target="_blank"
              >(+66)2-253-9800 (Bangkok Office)</a
            >
          </div>
        </li>
        <li class="item">
          <div class="icon">
            <FontAwesomeIcon
              :icon="{ prefix: 'fas', iconName: 'envelope' }"
            ></FontAwesomeIcon>
          </div>
          <div class="link">
            <a href="mailto:rcnsupport@reeracoen.co.th"
              >rcnsupport@reeracoen.co.th</a
            >
          </div>
        </li>
        <li class="item">
          <div class="icon">
            <FontAwesomeIcon
              :icon="{ prefix: 'fas', iconName: 'city' }"
              class="align-self-baseline mt-2"
            ></FontAwesomeIcon>
          </div>
          <div class="link">
            <a href="https://goo.gl/maps/rHjTNPkP6szGUdaS9" target="_blank"
              >801 8th Floor, Mercury Tower, 540 Ploenchit Road, Lumphini,
              Pathum Wan, Bangkok 10330
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faLinkedinIn,
  faLine
} from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope,
  faPhoneVolume,
  faDesktop,
  faCity
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebookF)
library.add(faLinkedinIn)
library.add(faLine)
library.add(faCity)
library.add(faEnvelope)
library.add(faDesktop)
library.add(faPhoneVolume)
export default {
  components: {
    FontAwesomeIcon
  }
}
</script>

<style lang="scss" src="./ContentFooter.scss" scoped />
