import isEmpty from 'lodash/isEmpty'

export default {
  methods: {
    hasError(errors) {
      return !isEmpty(errors)
    },
    errorHtmlClass(errors) {
      return !isEmpty(errors) ? 'error' : null
    }
  }
}
