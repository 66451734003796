import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faMapMarkerAlt,
  faMoneyBill,
  faUserTie
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faMapMarkerAlt)
library.add(faMoneyBill)
library.add(faUserTie)

const ViewProps = window._application.props

export default {
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      listJob: ViewProps.candidateSearchForm.candidates
    }
  },
  methods: {
    openRequestCV(params) {
      this.$modal.show('RequestCV', params)
    }
  }
}
